import facebook from "assets/images/icons/facebook-icon-white.svg";
import instagram from "assets/images/icons/instagram-icon-white.svg";
import pinterest from "assets/images/icons/pinterest-icon-white.svg";
import twitter from "assets/images/icons/twitter-icon-white.svg";
import Hacker from "assets/images/me.webp";
import p1 from "assets/images/p1.png";
import p2 from "assets/images/p2.png";
import p3 from "assets/images/p3.jpg";
import p4 from "assets/images/p4.jpg";
import p5 from "assets/images/p5.jpg";
import p6 from "assets/images/p6.jpg";
import person_1 from "assets/images/person_1.jpg";
import person_2 from "assets/images/person_2.jpg";
import person_3 from "assets/images/person_3.jpg";
import person_4 from "assets/images/person_4.jpg";
import CssSVG from "assets/images/svgs/css.svg";
import DjangoSVG from "assets/images/svgs/django.svg";
import HTMLSVG from "assets/images/svgs/html.svg";
import JsSVG from "assets/images/svgs/js.svg";
import ReactSVG from "assets/images/svgs/react.svg";

export const PORTFOLIO_EXTRA_ASSETS = {
  ReactSVG,
  JsSVG,
  HTMLSVG,
  DjangoSVG,
  CssSVG,
};

export const HACKER = Hacker;

export const PortolioProjects = {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
};
export const Persons = {
  person_1,
  person_2,
  person_3,
  person_4,
};

export const Social_Media_Icons = {
  facebook: facebook,
  twitter: twitter,
  instagram: instagram,
  pinterest: pinterest,
};
