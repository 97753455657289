import React from "react";

export default function Button({
  variant = "primary", //this is not working don't know why
  type = "submit",
  isLoading = false,
  disabled = false,
  label,
  handleClick,
  classNames = "",
  ...props
}) {
  const getButtonVariantClasses = () => {
    switch (variant) {
      case "primary":
        return "bg-primary";
      case "secondary":
        return "bg-secondary";
      case "success":
        return "bg-success";
      case "danger":
        return "bgdanger-";
      default:
        return "bg-primary";
    }
  };

  const buttonClasses = `d-flex justify-content-center text-white py-2 px-4 border ${getButtonVariantClasses()} ${
    classNames || ""
  }`;

  return (
    <button
      disabled={disabled}
      className={buttonClasses}
      type={type}
      onClick={handleClick}
      {...props}
    >
      {isLoading ? "loading..." : label}
    </button>
  );
}
