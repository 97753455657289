import React from "react";

import {
  faFacebookF,
  faGithub,
  faInstagram,
  faLinkedin,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="row mb-5">
        <p className="col-12 text-center">
          Copyright &copy;
          <script>document.write(new Date().getFullYear());</script>
          All rights reserved
        </p>
      </div>
      <div className="row mb-5">
        <div className="col-md-12 text-center">
          <p className="d-flex justify-content-center">
            <a
              href="https://www.facebook.com/keshab.bhadel.9"
              target="_blank"
              className="social-item d-flex p-3 align-items-center justify-content-center rounded-circle m-2"
              title="facebook"
            >
              <FontAwesomeIcon className="w-75 h-75" icon={faFacebookF} />
            </a>
            <a
              href="https://www.instagram.com/keshavbhadel/"
              target="_blank"
              className="social-item d-flex p-3 align-items-center justify-content-center rounded-circle m-2"
              title="instagram"
            >
              <FontAwesomeIcon className="w-75 h-75" icon={faInstagram} />
            </a>
            <a
              href="https://github.com/keshsu"
              target="_blank"
              className="social-item d-flex p-3 align-items-center justify-content-center rounded-circle m-2"
              title="github"
            >
              <FontAwesomeIcon className="w-75 h-75" icon={faGithub} />
            </a>
            <a
              href="https://www.linkedin.com/in/keshav-bhadel-a36176137/"
              target="_blank"
              className="social-item d-flex p-3 align-items-center justify-content-center rounded-circle m-2"
              title="linkedin"
            >
              <FontAwesomeIcon className="w-75 h-75" icon={faLinkedin} />
            </a>
            <a
              href="https://medium.com/@keshab.bhadel.5"
              target="_blank"
              className="social-item d-flex p-3 align-items-center justify-content-center rounded-circle m-2"
              title="medium"
            >
              <FontAwesomeIcon className="w-75 h-75" icon={faMedium} />
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
