import React, { useEffect, useState } from "react";

import { useNav } from "hooks/useNav";

import EducationCard from "components/EducationCard";
import ExperienceCard from "components/ExperienceCard";
import ExperienceTimeline from "components/ExperienceTimeline";

const Timeline = () => {
  const resumeRef = useNav("Resume");

  const [killed, setkilled] = useState(false);

  const educationHistory = [
    {
      id: 1,
      date: "2014",
      course: "SLC",
      description:
        "Schooling provides valuable knowledge, life skills, and personal growth. It teaches us academics, problem-solving, communication, discipline, and teamwork. School exposes us to diverse perspectives, fostering empathy and tolerance. It cultivates curiosity, adaptability, and a love for learning. School also instills resilience, hard work, and perseverance. These learnings form the foundation for our future success.",
      school_college: "Dolagiri Secondary School",
      university: "NEB",
    },
    {
      id: 2,
      date: "",
      course: "",
      description: "",
      school_college: "",
      university: "",
    },
    {
      id: 3,
      date: "2013 - 2015",
      course: "+2 in Business Studies",
      description:
        "This High school offers transformative learning experiences, fostering academic growth and life skills. It teaches time management, critical thinking, and effective communication. Diverse subjects and activities expand horizons and shape interests. High school cultivates resilience, adaptability, and perseverance. It nurtures friendships, teamwork, and collaboration. ",
      school_college: "Dolagiri Higher Secondary School",
      university: "HSEB",
    },
    {
      id: 4,
      date: "2016 - 2022",
      course: "Bachelor in Information Technology (BIT)",
      description:
        "With the help of better counseling and greater career objectives, I was able to achieve a bachelor's degree in Information technology in KIST College. It was a tough challenge for each one of us. We faced COVID which delays our 4 Years of Bachelors to 6 Years, even though i am so happy to say that i have cleared my bachelors degree with 3.43 CGPA. It was an awesome experience with the college premises. Got to learn a lot from there.",
      school_college: "Kist College",
      university: "Purbanchal University",
    },
  ];

  const experienceHistory = [
    {
      id: 1,
      date: "Nov 2019 - Dec 2019",
      title: "Internship Trainee",
      company: "Leapfrog Technology, Inc.",
      location: "Kathmandu, Bāgmatī, Nepal",
      description: `
          <ul>
            <li> In-depth knowledge in front end development with HTML CSS and JS.</li>
            <li> Created site layout and user interface using HTML and CSS practices.</li>
            <li> Designed, implemented and monitored web pages and sites for continuous improvement.</li>
            <li> Website development with reference to the PSD design mockups.</li>
            <li> Pure CSS on web development.</li>
            <li> Used programming capabilities in JavaScript and learned more about JS concepts., enhance visuals and strengthen search engine rankings.</li>
          </ul>`,
    },
    { id: 2, date: "", title: "", company: "", location: "", description: "" },
    {
      id: 3,
      date: "Dec 2019 - Dec 2020",
      title: "Frontend Web Developer",
      company: "Guras Technology Pvt. Ltd.",
      location: "Santinagar, Kathmandu, NepalSantinagar",
      description: `
          <ul>
            <li>Designed and Produced websites compatible with multiple browsers to meet usability and performance requirements.</li>
            <li>Applied Responsive web designs for the developed sites.</li>
            <li>Web templating for different client projects. Tested customer-facing prototypes before  deployment, applying best practice diagnostic techniques to verify usability.</li>
            <li>Created web interfaces for Content Management Systems to client.</li>
            <li>Developed landing pages, dashboards and online applications using HTML, CSS, JS, jQuery, Bootstrap.</li>
            <li>Learned and implemented PHP, Laravel Framework in different projects within the mentorship of senior developer.</li>
            <li>Learned Django app development in different projects as the company changed to the different stack for software development.</li>
          </ul>`,
    },
    {
      id: 4,
      date: "Aug 2020 - Jan 2022",
      title: "Full Stack Developer",
      company: "Soek Tech Solutions",
      location: "Santinagar, Kathmandu, NepalSantinagar",
      description: `
          <ul>
            <li>Designed and Produced websites compatible with multiple browsers to meet usability and performance requirements.</li>
            <li>Collected, defined and translated user requirements into project designs and implementation plans.</li>
            <li>Project Management, Monitoring, Development, Communicating with developers to reach out the determined task completion goal.</li>
            <li>Tested customer-facing prototypes before deployment, applying best practice diagnostic techniques to verify usability.</li>
            <li>Developed landing pages, dashboards and online applications using HTML, CSS, JS, jQuery, MySQL, Django and Laravel Framework.</li>
            <li>Used programming capabilities in React JS, MongoDB, Django, Laravel and Node, Express.js and other libraries as needed.</li>
          </ul>`,
    },
    {
      id: 5,
      date: "Mar 2022 - Nov 2022",
      title: "Associate React Developer",
      company: "Truemark Technology",
      location: "Santinagar, Kathmandu, NepalSantinagar",
      description: `
          <ul>
            <li>Using React Js to build / maintain the application.</li>
            <li>Responsive design and building components driven application.</li>
            <li>Used Context API with React and also learned basic concepts on REDUX & FLUX.</li>
            <li>JSON data building on front end application for Backend engineers.</li>
            <li>Used project management tools like Trello, Slack for team coordination.</li>
            <li>Performed project management on several task divisions.</li>
            <li>Worked as a team lead to accomplish the clients requirements.</li>
            <li>Code review and training junior.</li>
            <li>Worked with Scrum, Agile Software Development.</li>
          </ul>`,
    },
    {
      id: 6,
      date: "May 2023 - Present",
      title: "Javascript Engineer",
      company: "Open Learning Exchange (OLE) Nepal",
      location: "Sanepa, Lalitpur",
      description: `
          <ul>
            <li>Using Vanilla JS, JQuery  to build / maintain the application. </li>
            <li>Working in E-Path Software that is known for accomplishments and achievements that OLE has done over the years for the young children in rural areas.</li>
            <li>Developing new chapters on the E-Path application.</li>
            <li>Templating with HTML (Handlebars), XML Building, Preloading Assets and JSON Building and Implementation.</li>
          </ul>`,
    },
  ];

  useEffect(() => {
    if (!killed) {
      var edEl = document.getElementsByClassName("education");

      if (edEl.length) {
        var edbx = edEl[0];

        var divToKill = edbx.childNodes[1];
        var hideDot = edbx.childNodes[6];

        divToKill.classList.add("d-none");
        hideDot.classList.add("d-none");
        setkilled(true);
      }
    }
  }, [killed]);

  return (
    <section ref={resumeRef} className="site-section" id="section-resume">
      <div className="resume-container container">
        <div className="section-heading text-center">
          <span>Background</span>
          <h2>
            My <strong>Resume</strong>
          </h2>
        </div>
        <ExperienceTimeline>
          {experienceHistory.map((experience, index) => (
            <ExperienceCard {...experience} key={index} />
          ))}
          {educationHistory.map((education, index) => (
            <EducationCard {...education} key={index} />
          ))}
        </ExperienceTimeline>
      </div>
    </section>
  );
};

export default Timeline;
