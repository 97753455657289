import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceCard = ({ title, description, icon, link }) => (
  <div
    className="site-service-item site-animate fadeIn site-animated"
    data-animate-effect="fadeIn"
  >
    <div className="service-header d-flex justify-content-between align-items-center mb-3">
      <h3>{title}</h3>
      <FontAwesomeIcon icon={icon} />
    </div>
    <p>{description}</p>
    <p>
      <Link to={link.url} className="site-link">
        {link.text} <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    </p>
  </div>
);

export default ServiceCard;
