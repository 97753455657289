import React from "react";
import { Col, Nav, Row } from "react-bootstrap";

import {
  faCode,
  faDesktop,
  faFileAlt,
  faPencilRuler,
  faSearchDollar,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNav } from "hooks/useNav";

import ServiceCard from "components/ServiceCard";

import Resume from "assets/document/Resume.pdf";

const Services = () => {
  const servicesRef = useNav("About");

  const services = [
    {
      id: 1,
      title: "Web Design",
      icon: faDesktop,
      description: `Work is the culmination of interactive design, user interfaces and experience for users.`,
      link: {
        text: "Learn More",
        url: "#",
      },
    },
    {
      id: 2,
      title: "Web Development",
      icon: faCode,
      description: `Services that are designed to help businesses of all sizes succeed online. `,
      link: {
        text: "Learn More",
        url: "#",
      },
    },
    {
      id: 3,
      title: "Graphic Design",
      icon: faPencilRuler,
      description: `Culmination of creative visions, brilliant ideas and full commitment.`,
      link: {
        text: "Learn More",
        url: "#",
      },
    },
    {
      id: 4,
      title: "Digital Marketing",
      icon: faSearchDollar,
      description: `Digital presense to grow popularity and increase performance of your business.`,
      link: {
        text: "Learn More",
        url: "#",
      },
    },
  ];

  const handleClickNav = (scrollToId) => {
    document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section ref={servicesRef} className="site-section" id="section-about">
      <div className="container">
        <Row className="mb-4">
          <Col md={6}>
            <div className="row flex-column">
              {services.map((service, index) => (
                <div className="col-md-12 mb-3" key={index}>
                  <ServiceCard
                    key={service.id}
                    title={service.title}
                    description={service.description}
                    icon={service.icon}
                    link={service.link}
                  />
                </div>
              ))}
            </div>
          </Col>
          <Col md={6}>
            <div className="introduction-wrapper">
              <div className="section-heading">
                <span> Introduction</span>
                <h2>Hello ! I'm Keshab Bhadel</h2>
              </div>
              <div className="section-caption">
                <span>
                  Every solution begin with the problem to make world easy to
                  use.
                </span>
              </div>
              <div className="section-description">
                <p>
                  Welcome to my portfolio site! I am a professional IT Engineer
                  specializing in software development, graphic design, web
                  design, web development, JavaScript engineering, and digital
                  marketing. With a passion for creating visually stunning and
                  user-friendly websites and applications, I leverage my diverse
                  skill set to deliver innovative solutions tailored to your
                  needs. Explore my work and discover how I can help you achieve
                  your digital goals.
                </p>

                <p className="d-flex align-items-center ">
                  <Nav.Link
                    onClick={() => handleClickNav("section-contact")}
                    className="btn btn-primary btn-sm service-link"
                  >
                    <FontAwesomeIcon
                      width={100}
                      height={100}
                      icon={faUserAlt}
                    />
                    Hire Me
                  </Nav.Link>
                  <a
                    href={Resume}
                    className="btn btn-secondary btn-sm service-link"
                    download
                  >
                    <FontAwesomeIcon
                      width={100}
                      height={100}
                      icon={faFileAlt}
                    />
                    CV
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Services;
