import React from "react";

const Input = ({
  type,
  label,
  value,
  name,
  placeholder,
  error,
  errorMsg,
  disabled,
  onChange,
}) => (
  <div className="input-wrapper mb-2">
    {label && (
      <label className="text-xs text-gray-600 font-semibold">{label}</label>
    )}
    <input
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      className={`form-control  px-3 py-4 ${error && "border-red-500"}`}
    />
    {error && <span className="text-red-500 text-sm">{errorMsg}</span>}
  </div>
);

export default Input;
