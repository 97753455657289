import React from "react";
import ReactDOM from "react-dom/client";

import App from "./containers/App";
import "assets/scss/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
