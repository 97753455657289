import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import emailjs, { init } from "@emailjs/browser";
import { useFormik } from "formik";
import { ContactSchema } from "schema/Contact.schema";

import Button from "components/common/Button/Button";
import Input from "components/common/Input/Input";

const ContactForm = () => {
  const initialValues = { email: "", password: "" };

  const [message, setMessage] = useState({
    type: "",
    text: "",
  });

  init("rUiSzW67Msg5ezqc5");

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ContactSchema,
    onSubmit: async (values) => {
      const data = await emailjs
        .sendForm(
          "service_s0ffh6t",
          "template_keldzyh",
          form.current,
          "rUiSzW67Msg5ezqc5"
        )
        .then(
          (result) => {
            setMessage({ type: "success", text: result.text });
          },
          (error) => {
            setMessage({ type: "error", text: error.text });
          }
        );

      login(values);

      if (!data) return;

      setData("user", data.user);
    },
  });

  return (
    <form className="site-form" onSubmit={formik.handleSubmit}>
      <h3 className="mb-5">Get In Touch</h3>
      {message.text && <Alert variant={message.type}>{message.text}</Alert>}
      <div className="form-group mb-3">
        <Input
          onChange={formik.handleChange}
          name="name"
          value={formik.values.name}
          placeholder="Your Name"
          type="text"
          error={!!formik.touched.name && !!formik.errors.name}
          errorMsg={formik.errors.name || ""}
        />
      </div>
      <div className="form-group mb-3">
        <Input
          onChange={formik.handleChange}
          name="email"
          value={formik.values.email}
          placeholder="Your Email"
          type="email"
          error={!!formik.touched.email && !!formik.errors.email}
          errorMsg={formik.errors.email || ""}
        />
      </div>
      <div className="form-group mb-3">
        <Input
          onChange={formik.handleChange}
          name="phone"
          value={formik.values.phone}
          placeholder="Your Phone"
          type="text"
          error={!!formik.touched.phone && !!formik.errors.phone}
          errorMsg={formik.errors.phone || ""}
        />
      </div>
      <div className="form-group mb-5">
        <textarea
          name="message"
          className="form-control px-3 py-4"
          cols="30"
          rows="10"
          placeholder="Write a Message"
        >
          {formik.values.message}
        </textarea>
      </div>
      <div className="form-group mb-3">
        <Button
          variant="primary"
          type="submit"
          label="Send Message"
          isLoading={formik.isSubmitting}
        />
      </div>
    </form>
  );
};

export default ContactForm;
