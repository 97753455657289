import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useNav } from "hooks/useNav";

import { HACKER, PORTFOLIO_EXTRA_ASSETS } from "constants/images";

const Banner = () => {
  const homeRef = useNav("Home");

  return (
    <section
      ref={homeRef}
      className="site-section no-padding"
      id="section-home"
    >
      <Container>
        <div className="landing-page d-lg-flex justify-content-end position-relative">
          <div className="content-section">
            <div className="tag-line">
              <p className="p-1 px-3 text-black rounded-2">
                Fullstack Developer
              </p>
            </div>
            <div className="strong-quote">
              <h1 className="">
                Unleashing Creativity for <br />
                Digital Innovation
              </h1>
            </div>
            <div className="description-quote">
              <p className="text-white-50">
                Unleash digital potential with a versatile professional skilled
                in software dev, design, web dev, JavaScript, and digital
                marketing. Elevate your online presence and drive exponential
                growth in the digital era.
              </p>
            </div>
            <div className="chat-line">
              <Link to="/" className="text-uppercase">
                Let's Chat!
              </Link>
            </div>

            <div className="experience-section d-flex align-items-center flex-wrap">
              <div className="years">
                <span className="count">3+</span>
                <span>
                  YEARS <br />
                  EXPREIENCE
                </span>
              </div>
              <div className="projects">
                <span className="count">12</span>
                <span>
                  PROJECTS COMPLETED <br />
                  ON DIFFERENT STACKS
                </span>
              </div>
            </div>
          </div>
          <div className="portfolio-image-section position-relative">
            <div className="portfolio-image position-relative">
              <img src={HACKER} alt="portfolio" />
            </div>
            <div className="extra-assets position-absolute w-100 h-100 top-0 start-0">
              <div className="ea-item react sheild">
                <img
                  className="w-100 h-100"
                  src={PORTFOLIO_EXTRA_ASSETS.ReactSVG}
                  alt="React"
                />
              </div>
              <div className="ea-item html sheild">
                <img
                  className="w-100 h-100"
                  src={PORTFOLIO_EXTRA_ASSETS.HTMLSVG}
                  alt="HTML"
                />
              </div>
              <div className="ea-item django sheild">
                <img
                  className="w-100 h-100"
                  src={PORTFOLIO_EXTRA_ASSETS.DjangoSVG}
                  alt="Django"
                />
              </div>
              <div className="ea-item js sheild">
                <img
                  className="w-100 h-100"
                  src={PORTFOLIO_EXTRA_ASSETS.JsSVG}
                  alt="Javascript"
                />
              </div>
              <div className="ea-item css sheild">
                <img
                  className="w-100 h-100"
                  src={PORTFOLIO_EXTRA_ASSETS.CssSVG}
                  alt="CSS"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Banner;
