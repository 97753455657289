import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import {
  faFacebookF,
  faGithub,
  faInstagram,
  faLinkedin,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavContext } from "context/NavContext";
import gsap from "gsap";

const Header = () => {
  const headerRef = useRef();
  const navMenuRef = useRef();
  const wnavoverlayRef = useRef();
  const navmenulinksRef = useRef();
  const [navOpen, setNavOpen] = useState(false);
  const [navbarOnTop, setNavbarOnTop] = useState(false);
  const { pathname } = useLocation();

  const [offset, setOffset] = useState(0);
  const { activeLinkId } = useContext(NavContext);
  const navLinks = ["Home", "About", "Resume", "Projects", "Contact"];

  const handleNavClick = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (offset) {
      setNavbarOnTop(true);
    } else {
      setNavbarOnTop(false);
    }
  }, [offset]);

  useLayoutEffect(() => {
    if (navOpen) {
      gsap
        .timeline()
        .to(wnavoverlayRef.current, {
          display: "block",
          ease: "power1.inOut",
          duration: 0.3,
        })
        .to(navMenuRef.current, {
          display: "block",
          height: "auto",
          opacity: 1,
          duration: 0.3,
          ease: "power1.inOut",
          y: 0,
          // width: windowDimensions.width < 768 ? "100%" : "30%",
        })
        .to(navmenulinksRef.current, {
          duration: 0.3,
          ease: "power1.inOut",
          y: 0,
          opacity: 1,
        });
    } else {
      gsap
        .timeline()
        .to(navmenulinksRef.current, {
          duration: 0.3,
          ease: "power1.inOut",
          y: 50,
          opacity: 0,
        })
        .to(navMenuRef.current, {
          height: 0,
          opacity: 0,
          duration: 0.3,
          ease: "power1.inOut",
          y: "-100vh",
        })
        .to(wnavoverlayRef.current, {
          display: "none",
          ease: "power1.inOut",
          duration: 0.3,
        });
    }
  }, [navOpen]);

  const handleClickLogo = () => {
    document
      .getElementById("section-home")
      .scrollIntoView({ behavior: "smooth" });
  };

  const handleClickNav = (scrollToId) => {
    setNavOpen(false);
    document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" });
  };

  const renderNavLink = (content) => {
    const scrollToId = `section-${content.toLowerCase()}`;

    return (
      <Nav.Link
        key={content}
        onClick={() => handleClickNav(scrollToId)}
        className={activeLinkId === content ? "active" : ""}
      >
        {content}
      </Nav.Link>
    );
  };

  return (
    <header ref={headerRef}>
      <div
        role="banner"
        data-collapse="all"
        data-animation="default"
        data-duration="1200"
        data-easing="ease-in-out-quart"
        data-easing2="ease-in-out-quart"
        className={`navigation-bar nav-bar w-nav ${
          navbarOnTop ? "nav-opened" : ""
        }`}
      >
        <a
          href="/"
          onClick={handleClickLogo}
          aria-current="page"
          className="brand w-nav-brand"
        >
          <div className="brand-text">
            <span className="fw-bolder">Keshav</span> Bhadel
          </div>
        </a>
        <div className="navigation">
          <div className="nav-bar-info">
            <div className="nav-bar-contacts">
              <div>
                Email:  
                <a
                  href="mailto:your@email.com?subject=Your%20Subject"
                  className="link-white"
                >
                  keshab.bhadel.5@gmail.com
                </a>
              </div>
              <div>Phone:  +977 9843791367</div>
            </div>
          </div>
          <div
            className={`menu-button w-clearfix w-nav-button ${
              navOpen ? "w--open" : ""
            }`}
          >
            <div
              className="menu-icon cursor-pointer float-end"
              onClick={() => handleNavClick()}
              aria-hidden="true"
            >
              <div className="menu-icon-line-top"></div>
              <div className="menu-icon-line-bottom"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-nav-menu-open={navOpen ? true : false}
          ref={wnavoverlayRef}
        >
          <nav
            role="navigation"
            ref={navMenuRef}
            className="nav-menu w-nav-menu"
          >
            <div className="nav-menu-links" ref={navmenulinksRef}>
              {navLinks.map((nav) => renderNavLink(nav))}

              <div className="social-buttons nav-menu-social">
                <a
                  href="https://www.linkedin.com/in/keshav-bhadel-a36176137/"
                  target="_blank"
                  className="social-button w-inline-block"
                >
                  <FontAwesomeIcon className="w-75 h-75" icon={faLinkedin} />
                </a>
                <a
                  href="https://www.facebook.com/keshab.bhadel.9"
                  target="_blank"
                  className="social-button w-inline-block"
                >
                  <FontAwesomeIcon className="w-75 h-75" icon={faFacebookF} />
                </a>
                <a
                  href="https://github.com/keshsu"
                  target="_blank"
                  className="social-button w-inline-block"
                >
                  <FontAwesomeIcon className="w-75 h-75" icon={faGithub} />
                </a>
                <a
                  href="https://medium.com/@keshab.bhadel.5"
                  target="_blank"
                  className="social-button w-inline-block"
                >
                  <FontAwesomeIcon className="w-75 h-75" icon={faMedium} />
                </a>
                <a
                  href="https://www.instagram.com/keshavbhadel/"
                  target="_blank"
                  className="social-button w-inline-block"
                >
                  <FontAwesomeIcon className="w-75 h-75" icon={faInstagram} />
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
