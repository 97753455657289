import React from "react";

import ModalProvider from "context/ModalContext";
import NavProvider from "context/NavContext";

// import Blogs from "components/Blogs";
import Layout from "components/Layout";
import Banner from "components/sections/Banner";
import Contact from "components/sections/Contact";
import Portfolio from "components/sections/Portfolio";
import Services from "components/sections/Services";
import Timeline from "components/sections/Timeline";

const Home = () => (
  <ModalProvider>
    <NavProvider>
      <Layout>
        <div className="home-container">
          <Banner />
          <Services />
          <Timeline />
          <Portfolio />
          {/* <Testimonials /> */}
          {/* <Blogs /> */}
          <Contact />
        </div>
      </Layout>
    </NavProvider>
  </ModalProvider>
);

export default Home;
