import React from "react";

import { useNav } from "hooks/useNav";

import ContactForm from "components/forms/ContactForm";

const Contact = () => {
  const contactRef = useNav("Contact");

  return (
    <section ref={contactRef} className="site-section" id="section-contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="section-heading text-center">
              <h2>
                Get <strong>In Touch</strong>
              </h2>
            </div>
          </div>
          <div className="col-md-7 mb-5 mb-md-0">
            <ContactForm />
          </div>
          <div className="col-md-5 pl-md-5">
            <h3 className="mb-5">My Contact Details</h3>
            <ul className="site-contact-details">
              <li>
                <span className="text-uppercase">Email</span>
                <a
                  href="mailto:keshab.bhadel.5@gmail.com"
                  className="__cf_email__"
                  target="_blank"
                >
                  [Email Me]
                </a>
              </li>
              <li>
                <span className="text-uppercase">Phone</span>
                +977 9843791367
              </li>
              <li>
                <span className="text-uppercase">Address</span>
                Bhaktapur, Nepal <br />
                Changunarayan - 4 , Halchhap
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
