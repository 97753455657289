import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";

import { ModalContext } from "context/ModalContext";
import { gsap } from "gsap";

import PortfolioItem from "./PortfolioItem";

import { PortolioProjects } from "constants/images";

const PortfolioGrid = () => {
  const portfolioItem = [
    {
      className: "mockup",
      imageSrc: PortolioProjects.p1,
      link: PortolioProjects.p1,
      title: "Banksmeadow Pizza Box Design",
      filter: ["graphic_design"],
    },
    {
      className: "mockup",
      imageSrc: PortolioProjects.p2,
      link: PortolioProjects.p2,
      title: "The Accounting Factory Logo",
      filter: ["graphic_design"],
    },
    {
      className: "packaging",
      imageSrc: PortolioProjects.p3,
      link: PortolioProjects.p3,
      title: "Creative Package Design",
      filter: ["web_development"],
    },
    {
      className: "packaging",
      imageSrc: PortolioProjects.p4,
      link: PortolioProjects.p4,
      title: "Packaging Brand",
      filter: ["web_development"],
    },
    {
      className: "typography",
      imageSrc: PortolioProjects.p5,
      link: PortolioProjects.p5,
      title: "Isometric 3D Extrusion",
      filter: ["web_design"],
    },
    {
      className: "photography",
      imageSrc: PortolioProjects.p6,
      link: PortolioProjects.p6,
      title: "White Space Photography",
      filter: ["photography"],
    },
  ];

  const filtersDefault = [
    { label: "All", filter: "*", isChecked: true },
    { label: "Web Development", filter: "web_development", isChecked: false },
    { label: "Graphic Design", filter: "graphic_design", isChecked: false },
    { label: "Web Design", filter: "web_design", isChecked: false },
    { label: "Photography", filter: "photography", isChecked: false },
  ];

  const [filters, updateFilters] = useState(filtersDefault);
  const [filteredPortfolio, setFilteredPortfolio] = useState(portfolioItem);
  const gridRef = useRef(null);
  const modoverlayRef = useRef(null);
  const modContentRef = useRef(null);
  // state for storing the filter keyword, with an initial value of *, which matches everything
  const [filterKey, setFilterKey] = React.useState("*");

  // Modal statep
  const { isModalOpen, setModalOpen } = useContext(ModalContext);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);
    updateFilters(
      filters.map((f) => ({
        ...f,
        isChecked: f.filter === key,
      }))
    );
  };

  useEffect(() => {
    const gridItems = gridRef.current.children;

    gsap.to(gridItems, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        const newFilteredPortfolio = portfolioItem.filter((item) =>
          filterKey === "*" ? true : item.filter.includes(filterKey)
        );

        setFilteredPortfolio(newFilteredPortfolio);

        gsap.fromTo(
          gridItems,
          { opacity: 0, x: 20 }, // You can customize the animation properties
          {
            opacity: 1,
            x: 0,
            duration: 0.3,
            stagger: 0.1, // Optional stagger effect for a smoother animation
          }
        );
      },
    });
  }, [filterKey]);

  const openModal = (index) => {
    console.log("clicked");
    setModalOpen(true);
    setCurrentModalIndex(index);
    
    // Disable body scrolling when the modal is open
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setModalOpen(false);
    // Enable body scrolling when the modal is closed
    document.body.classList.remove("modal-open");
  };

  useLayoutEffect(() => {
    if (isModalOpen) {
      gsap
        .timeline()
        .to(modoverlayRef.current, {
          display: "block",
          ease: "power1.inOut",
          duration: 0.3,
        })
        .to(modContentRef.current, {
          display: "block",
          height: "auto",
          opacity: 1,
          duration: 0.3,
          ease: "power1.inOut",
          y: 0,
          // width: windowDimensions.width < 768 ? "100%" : "30%",
        });
    } else {
      gsap
        .timeline()
        .to(modContentRef.current, {
          height: 0,
          opacity: 0,
          duration: 0.3,
          ease: "power1.inOut",
          y: "-100vh",
        })
        .to(modoverlayRef.current, {
          display: "none",
          ease: "power1.inOut",
          duration: 0.3,
        });
    }
  }, [isModalOpen]);

  return (
    <div>
      <div className="filters">
        <ul>
          {filters.map((f) => (
            <li
              key={`${f.label}_key`}
              onClick={handleFilterKeyChange(f.filter)}
              className={f.isChecked ? "active" : ""}
              data-filter={f.filter}
              aria-hidden="true"
            >
              {f.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="filters-content">
        <div className="row grid" ref={gridRef}>
          {filteredPortfolio.map((portfolio, index) => (
            <PortfolioItem
              key={index}
              className={`single-portfolio cursor-pointer col-sm-4 filter-item ${portfolio.filter[0]}`}
              imageSrc={portfolio.imageSrc}
              title={portfolio.title}
              category={portfolio.category}
              link={portfolio.link}
              onClick={() => openModal(index)}
            />
          ))}
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div
          className="portfolio-overlay position-fixed top-0 start-0"
          onClick={closeModal}
          ref={modoverlayRef}
          aria-hidden
        >
          <div className="portfolio-modal" ref={modContentRef}>
            <div
              className={`menu-button w-clearfix w-nav-button float-end ${
                isModalOpen ? "w--open" : ""
              }`}
            >
              <div
                className="menu-icon cursor-pointer "
                onClick={() => closeModal}
                aria-hidden="true"
              >
                <div className="menu-icon-line-top"></div>
                <div className="menu-icon-line-bottom"></div>
              </div>
            </div>
            <div className="modal-content">
              <PortfolioItem
                className={`single-portfolio w-100`}
                imageSrc={filteredPortfolio[currentModalIndex].imageSrc}
                title={filteredPortfolio[currentModalIndex].title}
                category={filteredPortfolio[currentModalIndex].category}
                link={filteredPortfolio[currentModalIndex].link}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioGrid;
