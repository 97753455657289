import React, {  useState } from "react";

const EducationCard = ({
  date,
  course,
  school_college,
  description,
  university,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderContent = (description) => {
    const words = description.split(" ");
    const displayedWords = isExpanded ? words : words.slice(0, 50);
    const truncatedContent = displayedWords.join(" ");

    const htmlContent = isExpanded
      ? description
      : truncatedContent + (words.length > 50 ? "..." : "");

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  return (
    <div className="timeline-box timeline-content-img logo-timeline-arrow education-content">
      <div className="timeline-box-wrap">
        <span className="date">
          <span className="icon-calendar"></span>
          {date}
        </span>
        <div className="resume-item timeline-content">
          <h3>{course}</h3>
          <span>{school_college}</span>
          <div className="timeline-content-txt">
            <div className="description">
              {renderContent(description)}
              {description.length > 50 && (
                <span
                  onClick={toggleExpand}
                  aria-hidden="true"
                  className="d-block cursor-pointer text-end readmore"
                >
                  <u>{isExpanded ? "Read Less" : "Read More"}</u>
                </span>
              )}
            </div>
          </div>
          <span className="school">{university}</span>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
