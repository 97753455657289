import React from "react";

const PortfolioItem = ({
  className,
  imageSrc,
  title,
  category,
  ...restProps
}) => (
  <div className={className} {...restProps}>
    <div className="relative">
      <div className="thumb">
        <div className="overlay overlay-bg" />
        <img className="image img-fluid" src={imageSrc} alt={title} />
      </div>
    </div>
    <div className="p-inner">
      <h4>{title}</h4>
      <div className="cat">{category}</div>
    </div>
  </div>
);

export default PortfolioItem;
