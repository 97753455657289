import React from "react";

import { useNav } from "hooks/useNav";

import PortfolioGrid from "components/PortfolioGrid";

const Portfolio = () => {
  const portfolioRef = useNav("Projects");

  return (
    <section ref={portfolioRef} className="site-section" id="section-projects">
      <div className="container">
        <div className="section-heading text-center">
          <span>Works</span>
          <h2>
            Latest <strong>Projects</strong>
          </h2>
        </div>
        <PortfolioGrid />
      </div>
    </section>
  );
};

export default Portfolio;
