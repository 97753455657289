import React from "react";

const ExperienceTimeline = ({ children }) => {
  function sliceArrayIntoGroups(array, groupSize) {
    return array.reduce((result, _, index) => {
      if (index % groupSize === 0) {
        result.push(array.slice(index, index + groupSize));
      }

      return result;
    }, []);
  }

  const groupedArray = sliceArrayIntoGroups(children[0], 2);
  const groupedArray2 = sliceArrayIntoGroups(children[1], 2);

  return (
    <div className="about-timeline">
      <div className="start-point">
        <div className="black-dot"></div>
        <h4>Experience</h4>
        <div className="corner bl"></div>
      </div>

      <div className="timeline-main">
        {groupedArray.map((element, index) => (
          <div className="timeline-row" key={index}>
            {element}
            <div className="horizontal-line"></div>
            <div className="verticle-line"></div>
            <div className="corner top"></div>
            <div className="corner bottom"></div>
          </div>
        ))}

        <div className="timeline-row first">
          <div className="horizontal-line half right"></div>
          <div className="start-point">
            <div className="black-dot"></div>
            <div className="horizontal-line"></div>
            <div className="corner top"></div>
            <div className="corner bottom"></div>
            <h4>Education</h4>
          </div>
        </div>

        {groupedArray2.map((element, index) => (
          <div className="timeline-row education-row" key={index}>
            {element}
            <div className="horizontal-line"></div>
            <div className="verticle-line"></div>
            <div className="corner top"></div>
            <div className="corner bottom"></div>

            <div className="start-point end-point">
              <div className="black-dot"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceTimeline;
